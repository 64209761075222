exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-holdings-js": () => import("./../../../src/pages/holdings.js" /* webpackChunkName: "component---src-pages-holdings-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquiries-js": () => import("./../../../src/pages/inquiries.js" /* webpackChunkName: "component---src-pages-inquiries-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-press-list-js": () => import("./../../../src/templates/press-list.js" /* webpackChunkName: "component---src-templates-press-list-js" */)
}

